body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.container {
  max-width: 960px;
}

.icon-link > .bi {
  width: .75em;
  height: .75em;
}

.error {
    color: red;
    font-size: 14px;
}

.red {
    color: red;
}

.green {
    color: green;
}

.orange {
    color: #F7A600;
}

.form-error {
    color: red;
    font-size: 14px;
}


.roww {
    display: flex;
}

.button_long {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 10px 16px;
    line-height: 20px;
    min-width: 60px;
    word-break: keep-all;
    color: rgb(255, 255, 255);
    border-radius: 4px;
    min-height: 24px;
    border: medium;
    background-color: rgb(14, 203, 129);
    flex: 1 1 0%;
    width: 120px;
    margin: 8px 8px 0px 0px;
}

.button_short {
    margin: 8px 0px 0px;
    cursor: pointer;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 14px;
    font-family: inherit;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    padding: 10px 16px;
    line-height: 20px;
    min-width: 60px;
    color: rgb(255, 255, 255);
    border-radius: 4px;
    min-height: 24px;
    border: medium;
    background-color: rgb(246, 70, 93);
    width: 120px;
    flex: 1 1 0%;
}

.button_grey {
    margin: 8px 0px 0px;
    cursor: pointer;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 14px;
    font-family: inherit;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    padding: 10px 16px;
    line-height: 20px;
    min-width: 60px;
    color: rgb(255, 255, 255);
    border-radius: 4px;
    min-height: 24px;
    border: medium;
    background-color: rgb(108,117,125);
    width: 120px;
    flex: 1 1 0%;
}

.form_row {
    width: 250px;
    margin: 10px;
    font-size: 14px;
}

.order_tabs {

    width: 300px;
}

.order_tabs_title {
    cursor: pointer;
    display: inline;
    padding-right: 12px;
    font-weight: bold;
}

.order_tabs_title_active {
    cursor: pointer;
    display: inline;
    padding-right: 12px;
    color: #F7A600;
    font-weight: bold;
}

.tp_sl_mode_active {
  cursor: pointer;
  padding-right: 12px;
  color: #F7A600;
  font-weight: bold;
}

input {
    margin-bottom: 5px;
    margin-top: 5px;
}

.icons {
    width: 45px;
    height: 45px;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block; margin-left:-110px;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .icons {background-color: #black;}

div ul li a {
    text-decoration: none;
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

td a {
    text-decoration: none;
    color: #555555;
}

.pointer {
    cursor: pointer;
}

.by-button--disabled {
    cursor: not-allowed;
}

/* Modal */
.modal {
    height: 100hv;
    width: 100hv;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal_content {
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    transform: scale(0.5);
    transition: 0.4s all;
    width: 20vw;
}

.modal_content.active {
    transform: scale(1);
    min-height: 25vh;
    overflow: auto;
    max-height: 85vh;
}

.container.report_table {
    margin: 15px auto 15px auto;
}

.change_input {
    color: #F7A600;
    cursor: pointer;
    margin-top: -35px;
    position: absolute;
    right: 60px;
}